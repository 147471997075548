<script>
import { mapGetters } from 'vuex';
import Cart from './components/Cart/Link.vue';
import SearchBar from './components/SearchBar.vue';
import MobileMenu from './components/MobileMenu.vue';
import SearchBarCompact from './components/SearchBarCompact.vue';

export default {
  name: 'navbar',

  data() {
    return {
      links: [
        { name: 'Blog', href: '/blog' },
        { name: 'Om oss', href: '/about' },
        { name: 'Kontakt', href: '/kontakt' },
      ],
    };
  },

  computed: {
    ...mapGetters('misc', ['rootCategories']),

    showSearchBar() {
      return true;
    },
  },

  methods: {
    openCart() {
      this.$refs.cart.open();
    },
    changeLanguage(locale) {
      //
    },
  },

  components: {
    SearchBar,
    Cart,
    MobileMenu,
    SearchBarCompact,
  },
};
</script>

<template>
  <nav class="flex items-center px-6 py-4 md:py-2" style="background: #DDEAF0">
    <div class="hero">
      <localized-link class="navbar-brand" to="/">
        <img src="@/assets/logo.png" style="height: 75px; width: 200px; object-fit: contain">
      </localized-link>
    </div>

    <div class="px-16 flex-1 hidden sm:flex">
      <localized-link to="/kategorier">
        <p class="text-md" style="font-weight: 700; font-size: 14px;">{{ $t("categories") }}</p>
      </localized-link>
      <localized-link to="/tempus-bildbyraa-ett-bildarkiv-foer-historia" style="margin-left: 30px">
          <p class="text-md" style="font-weight: 700; font-size: 14px;">Om bildbyrån</p>
      </localized-link>
      <localized-link to="/bokillustrationer-under-femhundra-aar" style="margin-left: 30px">
            <p class="text-md" style="font-weight: 700; font-size: 14px;">Om bilderna</p>
      </localized-link>
      <localized-link to="/koepvillkor" style="margin-left: 30px">
              <p class="text-md" style="font-weight: 700; font-size: 14px;">Köpvillkor</p>
      </localized-link>
      <localized-link to="/kontakt" style="margin-left: 30px">
                <p class="text-md" style="font-weight: 700; font-size: 14px;">Kontakt</p>
      </localized-link>
      <localized-link to="/newsletter-link" style="margin-left: 30px">
        <p class="text-md" style="font-weight: 700; font-size: 14px;">Nyhetsbrev</p>
      </localized-link>
      <localized-link to="/tempus-picture-agency-a-picture-archive-for-history" style="margin-left: 30px">
          <p class="text-md" style="font-weight: 700; font-size: 14px;">In English</p>
        </localized-link>
    </div>


    <div class="flex-1 text-right block sm:hidden">
      <mobile-menu @onOpenCart="openCart"/>
    </div>

    <div class="hidden md:block" v-show="showSearchBar">
      <search-bar />
    </div>

    <div class="hidden sm:block md:hidden">
      <search-bar-compact />
    </div>

<!--    <img @click="changeLanguage('sv')" src="../../assets/sweden.svg" style="width: 20px; margin-right: 10px; cursor: pointer">-->
<!--    <img @click="changeLanguage('en')" src="../../assets/uk.svg" style="width: 20px; cursor: pointer">-->


    <cart ref="cart" />
  </nav>
</template>
