<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
      links: [
        { name: 'Kategorier', href: '/kategorier' },
        { name: 'Om bildbyrån', href: '/tempus-bildbyraa-ett-bildarkiv-foer-historia' },
        { name: 'Om bilderna', href: '/bokillustrationer-under-femhundra-aar' },
        { name: 'Köpvillkor', href: '/koepvillkor' },
        { name: 'Kontakt', href: '/kontakt' },
        { name: 'Nyhetsbrev', href: '/newsletter-link' },
        { name: 'In English', href: '/tempus-picture-agency-a-picture-archive-for-history' },
      ],
    };
  },

  computed: {
    ...mapState('cart', ['items']),
  },

  methods: {
    open() {
      this.show = true;
    },

    close() {
      this.show = false;
    },

    goTo(location) {
      this.close();
      this.$router.push(location);
    },

    openCart() {
      this.close();
      this.$emit('onOpenCart');
    },
  },
};
</script>

<template>
  <div>
    <div class="icon">
      <i class="fas fa-bars" @click="open()"></i>
    </div>
    <div v-if="show" id="menu">
      <div class="w-full">
        <button class="h-12 w-12 mt-8 mr-8 rounded-md text-2xl text-white bg-blue-500">
          <i class="fas fa-times" @click="close()"></i>
        </button>
      </div>

      <div class="flex-1 text-2xl my-10">
        <a v-for="(link, i) in links"
           :key="i"
           class="link"
           @click="goTo(link.href)"
        >
          {{ link.name }}
        </a>
      </div>
<!-- 
      <div class="my-24 text-3xl text-center">
        <button
          @click="openCart"
          :disabled="items.length === 0"
          class="text-blue-500"
        >
          <i class="fas fa-shopping-cart"></i>
          <p class="inline" v-if="items.length > 0">
            {{ items.length }}
          </p>
        </button>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  button:disabled {
    color: rgba(0, 0, 0, .25);
  }
</style>
